import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONSTANTS} from  './../constants/api.constants'
import { User } from '../_models/user';

@Injectable()
export class UserService {
    searchParameters: any;
    constructor(private http: HttpClient) { } 

    getAll() {
        return this.http.get<User[]>('/api/users');
    }
 
    getById(id: number) { 
        return this.http.get('/api/users/' + id);
    }

    getCurrentUserDetails() {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.USER.DETAILS;
        return this.http.post(url, {});
    }

     
    logoutCurrentUser() {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.USER.LOGOUT;
        return this.http.post(url, {});
    }

    create(user: User) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.USER.REGISTER;
        return this.http.post(url, user);
    }

    update(user: User) {
        return this.http.put('/api/users/' + user.id, user);
    }

    delete(id: number) {
        return this.http.delete('/api/users/' + id);
    }

    setSearchParameters(searchParameters){
        this.searchParameters = searchParameters
    }

    getSearchParameters(){
        if(!this.searchParameters) {
            this.searchParameters = {
                from_date: 1540191600 , 
                to_date : 1540202400
           }
        }
        return this.searchParameters;
    }
}