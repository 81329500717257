import { Component, OnInit } from '@angular/core';
import { SpeedLocationService } from '../_services/speedlocation.service';
import { IndoorDataService } from "../_services/indoordata.service";
import { UserService } from "../_services/user.service";
import { UtilityService } from "../_services/utility.service"; 
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { API_CONSTANTS} from  '../constants/api.constants'
//import * as L from 'leaflet';
import { icon, polygon, circle ,latLng, Map, marker,Marker,LatLngExpression, point, polyline, tileLayer } from 'leaflet';
//import 'leaflet.markercluster';
// declare const google: any;
 declare var L;
declare var HeatmapOverlay;
//import {} from '@types/googlemaps';
@Component({
  selector: 'app-speed-location',
  templateUrl: './roadparking.component.html',
  styleUrls: ['./roadparking.component.css']
})


export class RoadparkingComponent implements OnInit {

  heatmap: any;
  map: any;
  leafmap :any;
  showMap: boolean = false;
  sourceCities = [];
  sourceLocation = [];
  targetLocation = [];
  targetCities = [];

  sourceCounties = [];
  targetCounties = [];

  roadParkingEvent = [];
  roadSearched = [];

  enableTab1: boolean = true;
  enableTab2: boolean = false;
  loading: boolean = false;
  overlays: any[];
  data = {
    data: []
  };
  index: number = 0;
  bounds = [];
  cols: any[];
 

  heatmapLayer = new HeatmapOverlay(API_CONSTANTS.LEAFLET.HEATMAP.HEATMAP_LAYER );
  layersControl = {
    baseLayers: {
      'Open Cycle Map': tileLayer('http://{s}.tile.opencyclemap.org/{z}/{x}/{y}.png',
       API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL),
      'Open Street Map': tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', 
       API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL)
    },
    position: 'bottomright' 
  }

	// Values to bind to Leaflet Directive
	layersControlOptions = { position: 'bottomright' };

    options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      API_CONSTANTS.LEAFLET.HEATMAP.OPTIONS_LAYERS),
      this.heatmapLayer,
    ],
    maxZoom: API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL.maxZoom ,
    zoom:4,
  };

	markerClusterData: any[] = [];


  onMapReady(map: L.Map) {
    this.leafmap = map
    window.dispatchEvent(new Event('resize'));
  }

  drawRoadMap(){
    var myIcon = L.AwesomeMarkers.icon({ 
      icon: 'car', 
      markerColor: 'green',
      prefix: 'fa', 
      iconColor: 'darkred',
    });
  
    
    //var myIcon = L.icon(API_CONSTANTS.LEAFLET.ICON.MARKER);
      
    const markerData: any[] = [];
    var customOptions = {'maxWidth': '500', 'className' : 'custom' }
      
    this.roadSearched.forEach((element, i) => {
     
        this.data.data.push({
        lat: element.event_lat,
        lng: element.event_lon,
        count: 1
      });
      this.bounds.push([element.event_lat, element.event_lon]);
      let contentString = this.utilityService.drawLeafletInfoWindow(element, 'parking');
      markerData.push(marker([element.event_lat, element.event_lon], {icon: myIcon}).bindPopup(contentString).openPopup());
      });

      if (markerData.length > 0) {
      this.heatmapLayer.setData(this.data);
      this.markerClusterData = markerData;

       //this.leafmap.addLayer(markers);
      this.leafmap.fitBounds(this.bounds);
      this.leafmap.setZoom(13);

    } 
   
}
 
	resizeMap() {
    window.dispatchEvent(new Event('resize'));
  }
  constructor(private speedLocationService: SpeedLocationService,
    private _indoorDataService: IndoorDataService, private userService: UserService,
    private utilityService : UtilityService
  ) { }

  ngOnInit() {
   
    this.countyData()

    this.cols = [
      { field: 'road_osmid', header: 'OSM ID' },
      { field: 'roadname', header: 'Road Name' },
      { field: 'roadref', header: ' Road Ref' },
      { field: 'totalevents', header: 'Parking Event' },
     
  ];
  }

  countyData() {
    this.speedLocationService.getCounty().subscribe(
      (data: any) => {
        this.sourceCounties = data.success.county;
      },
      error => {
        console.log(error, 'this.countyData error')
      });
  }

  areaLocationData() {
    this.speedLocationService.getAreaLocation().subscribe(
      (data: any) => {
        this.sourceCities = data.success.area;
        this.sourceLocation = data.success.location;
        this.sourceCounties = data.success.county;
      },
      error => {
        console.log(error, 'this.areaLocationData error')
      });
  }


  search() {
    this.enableTab1 = false;
    this.enableTab2 = true;
    this.loading = true;

    let dateObj = this.userService.getSearchParameters();
    let requestObj = {
      targetCities: this.targetCities,
      targetLocation: this.targetLocation,
      targetCounties: this.targetCounties,
      from_date: dateObj.from_date,
      to_date: dateObj.to_date
    }


   // Getting data for Road Analysis Data Table
    this.speedLocationService.getRoadParkingData(requestObj).subscribe(
      (data: any) => {
        this.loading = false;
        this.roadParkingEvent = data.success;

          this.roadParkingEvent.forEach((element, i) => {
          this.roadParkingEvent[i]['road_osmid'] = parseFloat(element['road_osmid']) ;
          this.roadParkingEvent[i]['totalevents'] = parseFloat(element['totalevents']);  
         });
      },
      error => {
        this.loading = false;
        console.log(error, 'this.roadParkingEvent error')
      });
   // Getiing Data for Maps
    this.speedLocationService.getRoadParkingDataMap(requestObj).subscribe(
      (data: any) => {
        this.loading = false;
        this.roadSearched = data.success;
        this.showMap = true;
        this.drawRoadMap();
       },
      error => {
        this.loading = false;
        console.log(error, 'this.roadSearched error')
      });

      this.enableTab1 = false;
      this.enableTab2 = true;
   
      this.loading = true;
      this.showMap = true;
      this.index = 1;
      window.dispatchEvent(new Event('resize'));

    
    }

    handleChange(e) {
      window.dispatchEvent(new Event('resize'));
      this.index = 1;
  }
  

  onMoveToTarget() {
    console.log('onMoveToTarget')
    this.speedLocationService.getAreaByCountyId({ targetCounties: this.targetCounties }).subscribe(
      (data: any) => {
        this.sourceCities = data.success.area;
        this.sourceLocation = data.success.location;
      },
      error => {
        console.log(error, 'this.areaLocationData error')
      });
  }
 
  }