import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import {API_CONSTANTS} from  '../constants/api.constants'

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }

    login(email: string, password: string) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.USER.LOGIN;
        return this.http.post<any>(url, { email: email, password: password })
            .map(user => {
                // login successful if there's a jwt token in the response   
                if (user.success && user.success.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }
                return user;
            });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}