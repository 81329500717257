import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONSTANTS} from  './../constants/api.constants'
import { User } from '../_models/user';

@Injectable()
export class SpeedLocationService {  
    constructor(private http: HttpClient) { } 

    searchAreaLocation(requestObj) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.SPEED_LOCATION.SEARCH_AREA_LOCATION;
        return this.http.post(url, requestObj);
    }
    
    
    searchRoadCongestion(requestObj) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.ROAD_CONGESTION.SEARCH_ROAD_CONGESTION;
        return this.http.post(url, requestObj);
    }

    searchAreaSpecificLocation(requestObj) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.SPEED_LOCATION.SEARCH_SPECIFIC_LOCATION;
        return this.http.post(url, requestObj);
    }

    searchAreaRoad(requestObj) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.SPEED_LOCATION.SEARCH_AREA_ROAD;
        return this.http.post(url, requestObj);
    }

    searchAreaMap(requestObj) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.SPEED_LOCATION.SEARCH_AREA_ROAD_MAP;
        return this.http.post(url, requestObj);
    }

    getRoadParkingData(requestObj){
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.SPEED_LOCATION.GET_ROAD_PARKING_DATA;
        return this.http.post(url, requestObj);
    }
    getRoadParkingDataMap(requestObj){
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.SPEED_LOCATION.GET_ROAD_PARKING_DATA_MAP;
        return this.http.post(url, requestObj);
    }
    getAreaLocation() {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.SPEED_LOCATION.GET_AREA_LOCATION;
        return this.http.get(url);
    } 

    getCounty() {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.SPEED_LOCATION.GET_COUNTY;
        return this.http.get(url);
    }

    getAreaByCountyId(county) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.SPEED_LOCATION.GET_AREA_BY_COUNTY;
        return this.http.post(url, county);
    }


    getRoadCongestionAreaByCountyId(county) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.ROAD_CONGESTION.GET_ROAD_CONGESTION_BY_COUNTY;
        return this.http.post(url, county);
    }

    getRoadCongestionTab3(requestObj) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.ROAD_CONGESTION.GET_ROAD_CONGESTION_TAB3;
        return this.http.post(url, requestObj);
    }


    
    getById(id: number) {
        return this.http.get('/api/users/' + id);
    }

    getCurrentUserDetails() {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.USER.DETAILS;
        return this.http.post(url, {});
    }

    
    logoutCurrentUser() {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.USER.LOGOUT;
        return this.http.post(url, {});
    }

    create(user: User) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.USER.REGISTER;
        return this.http.post(url, user);
    }

    update(user: User) {
        return this.http.put('/api/users/' + user.id, user);
    } 

    delete(id: number) {
        return this.http.delete('/api/users/' + id);
    } 
}