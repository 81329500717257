import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {  UserService } from './../../_services/user.service';
import { AlertService } from '../../_services/alert.service';

@Component({
  selector: 'header-layout',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	toSelectedMoments: any = new Date(2018, 11, 24, 10, 33, 30, 0);
	fromSelectedMoments : any;
	fixed: boolean = false;
	loggedInUser:any;
	userDetails = {};
	constructor(
		private userService: UserService,
		private alertService: AlertService,
		private router: Router) { }

	ngOnInit() {
		this.toSelectedMoments = new Date(2018, 11, 24, 10, 33, 30, 0);
		this.fromSelectedMoments = new Date();
		this.loggedUser();
	}

	@HostListener('window:scroll', ['$event']) onWindowScroll(e) {
	  const number = e.target.documentElement.scrollTop;
	  if (number > 80) {
	    this.fixed = true;
	  } else if (this.fixed && number < 70) {
	    this.fixed = false;
	  }
	}

	loggedUser(){
		const helper = new JwtHelperService();
		let userInfo = localStorage.getItem('currentUser');
		this.loggedInUser = JSON.parse(userInfo);
		console.log(this.loggedInUser, 'this.loggedInUser ') 
		if(this.loggedInUser){
			let token = this.loggedInUser.success.token;
			const decodedToken = helper.decodeToken(token);
			const expirationDate = helper.getTokenExpirationDate(token);
			const isExpired = helper.isTokenExpired(token)
			console.log(decodedToken, isExpired);
			this.userDetails = this.userService.getCurrentUserDetails().subscribe(
				(data:any) => {
					this.userDetails = data.success;
					console.log(this.userDetails, data, 'this.userDetails')
				},
				error => {
					console.log(this.userDetails, error, 'this.userDetails error')
				});
		

		}
		
	}

	logout(){

		this.userDetails = this.userService.logoutCurrentUser().subscribe(
			(data:any) => {
				localStorage.removeItem("currentUser");
				localStorage.clear();
				this.alertService.success('Logout successful', true);
				this.router.navigate(['login']);
			},
			error => {
				console.log(error, 'logout')
			});
		
	}

	search(){
		this.setDate();
	}

	setDate(){
		console.log(this.fromSelectedMoments, this.toSelectedMoments, 'setDate')
		let fromDate = 0;
		let toDate = 0;
		if(this.fromSelectedMoments){
			fromDate =  Math.round((new Date(this.fromSelectedMoments[0])).getTime() / 1000);
		}

		if(this.toSelectedMoments){
			toDate =  Math.round((new Date(this.toSelectedMoments[1])).getTime() / 1000);
		}

		let searchObject = {
			 from_date: fromDate, 
			 to_date : toDate
		}
		this.userService.setSearchParameters(searchObject)
	}
}
