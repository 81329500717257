import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONSTANTS} from  './../constants/api.constants'
import { User } from '../_models/user';

@Injectable()
export class ParkingLocationService {  
    constructor(private http: HttpClient) { } 

    searchAreaLocation(requestObj) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.PARKING_LOCATION.SEARCH_AREA_LOCATION;
        return this.http.post(url, requestObj);
    }
    
    searchAreaSpecificLocation(requestObj) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.PARKING_LOCATION.SEARCH_SPECIFIC_LOCATION;
        return this.http.post(url, requestObj);
    }

    // searchAreaRoad(requestObj) {
    //     let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.PARKING_LOCATION.SEARCH_AREA_ROAD;
    //     return this.http.post(url, requestObj);
    // }

    // searchAreaMap(requestObj) {
    //     let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.PARKING_LOCATION.SEARCH_AREA_ROAD_MAP;
    //     return this.http.post(url, requestObj);
    // }


    getAreaLocation() {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.PARKING_LOCATION.GET_AREA_LOCATION;
        return this.http.get(url);
    } 

    getCounty() {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.PARKING_LOCATION.GET_COUNTY;
        return this.http.get(url);
    }

    getAreaByCountyId(county) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.PARKING_LOCATION.GET_AREA_BY_COUNTY;
        return this.http.post(url, county);
    }
    
    getById(id: number) {
        return this.http.get('/api/users/' + id);
    }

    getCurrentUserDetails() {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.USER.DETAILS;
        return this.http.post(url, {});
    }

    
    logoutCurrentUser() {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.USER.LOGOUT;
        return this.http.post(url, {});
    }

    create(user: User) {
        let url = API_CONSTANTS.SERVER+'/'+API_CONSTANTS.USER.REGISTER;
        return this.http.post(url, user);
    }

    update(user: User) {
        return this.http.put('/api/users/' + user.id, user);
    } 

    delete(id: number) {
        return this.http.delete('/api/users/' + id);
    } 
}