import { Component, OnInit } from '@angular/core';
import { SpeedLocationService } from './../_services/speedlocation.service';
import { IndoorDataService } from "./../_services/indoordata.service";
import { UserService } from "./../_services/user.service";
import { UtilityService } from "./../_services/utility.service"; 
import { API_CONSTANTS} from  './../constants/api.constants'
import { LeafletModule } from '@asymmetrik/ngx-leaflet';


import { icon, polygon, circle ,latLng, Map, marker,Marker,LatLngExpression, point, polyline, tileLayer } from 'leaflet';
import 'leaflet.awesome-markers';

declare var L;
declare var HeatmapOverlay;

@Component({
  selector: 'app-speed-location',
  templateUrl: './speedlocation.component.html',
  styleUrls: ['./speedlocation.component.css']
})
export class SpeedLocationComponent implements OnInit {

  speedLocationTable: any;
  roadsSearched = [];
  showMap: boolean = false;
  sourceCities = []
  sourceLocation = []
  targetLocation = [];
  targetCities = [];

  sourceCounties = [];
  targetCounties = []

  citiesSearched = [];
  targetLocationStr = '';


  enableTab1: boolean = true;
  enableTab2: boolean = false;
  enableTab3: boolean = false;
  loading: boolean = false;

  location3rdTab: any = [];


  leafmap: any;
  data = {
    data: []
  };
  bounds = [];
  mapData = []

  leafmap1: any;
  data1 = {
    data: []
  };
  bounds1 = [];
  mapData1 = [];
  index: number = 0;
  cols: any[];


  //  myIcon = L.icon(API_CONSTANTS.LEAFLET.ICON.MARKER);

  myIcon = L.AwesomeMarkers.icon({ 
    icon: 'car', 
    markerColor: 'green',
    prefix: 'fa', 
    iconColor: 'darkred',
  });

  myIcon1 = L.AwesomeMarkers.icon({ 
    icon: 'car', 
    markerColor: 'green',
    prefix: 'fa', 
    iconColor: 'darkred',
  //  className: 'awesome-marker awesome-marker-square'
  });


  customOptions =   {'maxWidth': '500', 'className': 'custom'};
  markerClusterData: any[] = [];
  markerClusterData1: any[] = [];

  heatmapLayer = new HeatmapOverlay(API_CONSTANTS.LEAFLET.HEATMAP.HEATMAP_LAYER );
  layersControl = {
    baseLayers: {
      'Open Cycle Map': tileLayer('http://{s}.tile.opencyclemap.org/{z}/{x}/{y}.png',
       API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL),
      'Open Street Map': tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', 
       API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL)
    },
    position: 'bottomright' 
  }

	// Values to bind to Leaflet Directive
	layersControlOptions = { position: 'bottomright' };

    options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      API_CONSTANTS.LEAFLET.HEATMAP.OPTIONS_LAYERS),
      this.heatmapLayer,
    ],
    maxZoom: API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL.maxZoom ,
    zoom:4,
  };


 // heatmapLayer1 = new HeatmapOverlay(API_CONSTANTS.LEAFLET.HEATMAP.HEATMAP_LAYER );
  layersControl1 = {
    baseLayers: {
      'Open Cycle Map': tileLayer('http://{s}.tile.opencyclemap.org/{z}/{x}/{y}.png',
       API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL),
      'Open Street Map': tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', 
       API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL)
    },
    position: 'bottomright' 
  }

	// Values to bind to Leaflet Directive
	layersControlOptions1 = { position: 'bottomright' };

    options1 = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      API_CONSTANTS.LEAFLET.HEATMAP.OPTIONS_LAYERS),
      //this.heatmapLayer,
    ],
    maxZoom: API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL.maxZoom ,
    zoom:4,
  };

  handleChange(e) {
  window.dispatchEvent(new Event('resize'));
  this.index = 2;
  }


  onMapReady(map: L.Map) {
  this.leafmap = map;
  window.dispatchEvent(new Event('resize'));
  }


  resizeMap() {
  window.dispatchEvent(new Event('resize'));
  }


  onMapReadyDetails(map: L.Map) {
  this.leafmap1 = map;
  window.dispatchEvent(new Event('resize'));
  }


  drawCityMap() {
    const markerData: any[] = [];
    
    this.citiesSearched.forEach((element, i) => {
      let lat = parseFloat (element.centrelatitude);
      let lng = parseFloat (element.centrelongitude);
      this.data.data.push({
          lat: element.centrelatitude,
          lng: element.centrelongitude,
          count: 1
        });
        
     

    
        this.citiesSearched[i]['pois_osmid'] = parseFloat(element['pois_osmid']) ;
        this.citiesSearched[i]['totalevents'] = parseFloat(element['totalevents']);  
     

      this.bounds.push([element.centrelatitude, element.centrelongitude]); 
      let contentString = this.utilityService.drawLocationInfoWindow(element, 'SPEEDING');
      markerData.push(marker([lat, lng], {icon: this.myIcon}).bindPopup(contentString).openPopup());

      // this.myIcon = L.AwesomeMarkers.icon({ 
      //   icon: API_CONSTANTS.FONT_AWESOME.ICONS[element.fclass], 
      //   markerColor: 'green',
      //   prefix: 'fa', 
      //   iconColor: 'darkred',
      // });
   

      // L.marker([lat, lng], { icon: this.myIcon }).addTo(this.leafmap)
      // .bindPopup(contentString, this.customOptions)
      // .openPopup();;
    });

    //if (markerData.length > 0) {
      this.heatmapLayer.setData(this.data);
      this.markerClusterData = markerData;

       //this.leafmap.addLayer(markers);
      this.leafmap.fitBounds(this.bounds);
      this.leafmap.setZoom(10);

  //  } 
    //  this.heatmapLayer.setData(this.data);
    // this.leafmap.fitBounds(this.bounds);
    // this.leafmap.setZoom(13);
  }

  locationAnalysis() {
    const markerData: any[] = [];
    this.roadsSearched.forEach((element, i) => {
      let lat = parseFloat (element.event_lat);
      let lng = parseFloat (element.event_lon);

      this.data1.data.push({
        lat: lat,
        lng: lng,
        count: 1
      });

      let contentString = this.utilityService.drawLeafletInfoWindow(element, 'location-details')

      this.bounds1.push([lat, lng]);
      // L.marker([lat, lng], { icon: this.myIcon1 }).addTo(this.leafmap1)
      //   .bindPopup(contentString, this.customOptions)
      //   .openPopup();;
      markerData.push(marker([lat, lng], {icon: this.myIcon1}).bindPopup(contentString).openPopup());

    });
    // this.heatmapLayer1.setData(this.data1);
    // this.leafmap1.fitBounds(this.bounds1);
    // this.leafmap1.setZoom(13);

    if (markerData.length > 0) {
      //this.heatmapLayer1.setData(this.data1);
      this.markerClusterData1 = markerData;

       //this.leafmap.addLayer(markers);
      this.leafmap1.fitBounds(this.bounds);
      this.leafmap1.setZoom(13);

    } 
  }


  constructor(private speedLocationService: SpeedLocationService,
    private _indoorDataService: IndoorDataService, private userService: UserService,
    private utilityService : UtilityService
  ) { }

  ngOnInit() {
    this.countyData()


    this.cols = [
      { field: 'pois_osmid', header: 'Place id' },
      { field: 'poisname', header: 'Name' },
      { field: 'totalevents', header: 'Speeding Event' },
     
  ];
  }

  
  countyData() {
    this.speedLocationService.getCounty().subscribe(
      (data: any) => {
        this.sourceCounties = data.success.county;
      },
      error => {
        console.log(error, 'this.countyData error')
      });
  }

  areaLocationData() {
    this.speedLocationService.getAreaLocation().subscribe(
      (data: any) => {
        this.sourceCities = data.success.area;
        this.sourceLocation = data.success.location;
        this.sourceCounties = data.success.county;
      },
      error => {
        console.log(error, 'this.areaLocationData error')
      });
  }

  search() {
    this.enableTab1 = false;
    this.enableTab2 = true;
    this.enableTab3 = false;
    this.loading = true;

    let dateObj = this.userService.getSearchParameters();
    let requestObj = {
      targetCities: this.targetCities,
      targetLocation: this.targetLocation,
      targetCounties: this.targetCounties,
      from_date: dateObj.from_date,
      to_date: dateObj.to_date
    }

    this.targetLocationStr = '';
    this.targetLocation.forEach((element, i) => {
      if (i > 0) {
        this.targetLocationStr += ', '
      }
      this.targetLocationStr += element.fclass.toUpperCase();
    });
    console.log(this.targetLocationStr, 'this.targetLocationStr')

    this.speedLocationService.searchAreaLocation(requestObj).subscribe(
      (data: any) => {
        this.loading = false;
        this.citiesSearched = data.success;
        this.showMap = true;
        this.drawCityMap();
      },
      error => {
        this.loading = false;
        console.log(error, 'this.citiesSearched error')
      });

      this.enableTab1 = false;
      this.enableTab2 = true;
      this.enableTab3 = false;
      
      this.loading = true;
      this.showMap = true;
      this.index = 1;
      window.dispatchEvent(new Event('resize'));
  }

 
  onMoveToTarget() {
    console.log('onMoveToTarget')
    this.speedLocationService.getAreaByCountyId({ targetCounties: this.targetCounties }).subscribe(
      (data: any) => {
        this.sourceCities = data.success.area;
        this.sourceLocation = data.success.location;
      },
      error => {
        console.log(error, 'this.areaLocationData error')
      });
  }

  clicked(event: Event) {
    console.log(event, 'event')
  }

  selectCity(event: Event, city: any) {
    this.location3rdTab = [];
    this.location3rdTab.push(city)

    this.enableTab1 = false;
    this.enableTab2 = false;
    this.enableTab3 = true;

    console.log(city, 'city')

    let dateObj = this.userService.getSearchParameters();
    let requestObj = {
      targetCities: this.targetCities,
      targetLocation: this.targetLocation,
      targetCounties: this.targetCounties,
      from_date: dateObj.from_date,
      to_date: dateObj.to_date,
      selected_osm_id: city.pois_osmid
    }

    this.speedLocationService.searchAreaSpecificLocation(requestObj).subscribe(
      (data: any) => {

        this.roadsSearched = data.success;
        this.speedLocationTable = this.utilityService.sortNumeric(data.speedLocationTable, ['totalevents']);
        console.log(this.speedLocationTable, 'this.this.speedLocationTable error')
        this.locationAnalysis()
      }, error => {
        console.log(error, 'this.areaLocationData error')
      });
  }



}



