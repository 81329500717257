import { Component, OnInit } from '@angular/core';
import { SpeedLocationService } from '../_services/speedlocation.service';
import { IndoorDataService } from "../_services/indoordata.service";
import { UserService } from "../_services/user.service";
import { UtilityService } from "../_services/utility.service"; 
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { API_CONSTANTS} from  './../constants/api.constants'

import { icon, polygon, circle ,latLng, Map, marker,Marker,LatLngExpression, point, polyline, tileLayer } from 'leaflet';


declare const google: any;
declare var L;
declare var HeatmapOverlay;
//import {} from '@types/googlemaps';
@Component({
  selector: 'app-speed-location',
  templateUrl: './streetparkingleaf.component.html',
  styleUrls: ['./streetparkingleaf.component.css']
})


export class StreetParkingLeafComponent implements OnInit {

  heatmap: any;
  map: any;
  leafmap :any;
  showMap: boolean = false;
  sourceCities = [];
  sourceLocation = [];
  targetLocation = [];
  targetCities = [];

  sourceCounties = [];
  targetCounties = [];

  roadParkingEvent = [];
  roadSearched = [];

  enableTab1: boolean = true;
  enableTab2: boolean = false;
  loading: boolean = false;
  overlays: any[];
  data = {
    data: []
  };
  bounds = [];
  mapData = []

  heatmapLayer = new HeatmapOverlay(API_CONSTANTS.LEAFLET.HEATMAP.HEATMAP_LAYER);

  options = {
    layers: [
      L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      API_CONSTANTS.LEAFLET.HEATMAP.OPTIONS_LAYERS),
      this.heatmapLayer,
    ],
    zoom: 13,
  };
 
  layersControl = {
    baseLayers: {
      'Open Street Map': tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL
      ),
      'Open Cycle Map': tileLayer('http://{s}.tile.opencyclemap.org/{z}/{x}/{y}.png',
      API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL
      )
    },
  }

  drawRoadMap(){

   var myIcon = L.icon(API_CONSTANTS.LEAFLET.ICON.MARKER);
   
   this.roadSearched.forEach((element, i) => {
      this.data.data.push({
       lat: element.event_lat,
       lng: element.event_lon,
       count: 1
     });

    var customOptions =
    {
    'maxWidth': '500',
    'className' : 'custom'
    }
     let datefevent = new Date(element.endparktime*1000).toLocaleString();
      let poisName = '';
      if(element.pois_name) {
        poisName =  element.pois_name;
      }

    let contentString = this.utilityService.drawLeafletInfoWindow(element, 'parking')

     this.bounds.push([element.event_lat, element.event_lon]);
     L.marker([element.event_lat, element.event_lon], {icon: myIcon}).addTo(this.leafmap)
    .bindPopup(contentString,customOptions)
    .openPopup();;
     
     });
     this.heatmapLayer.setData(this.data);
     this.leafmap.fitBounds(this.bounds);
     this.leafmap.setZoom(13);
   }

  onMapReady(map: L.Map) {
    this.leafmap = map
   
  }
  constructor(private speedLocationService: SpeedLocationService,
    private _indoorDataService: IndoorDataService, private userService: UserService,
    private utilityService : UtilityService
  ) { }

  ngOnInit() {
    this.countyData()
  }

  countyData() {
    this.speedLocationService.getCounty().subscribe(
      (data: any) => {
        this.sourceCounties = data.success.county;
      },
      error => {
        console.log(error, 'this.countyData error')
      });
  }

  areaLocationData() {
    this.speedLocationService.getAreaLocation().subscribe(
      (data: any) => {
        this.sourceCities = data.success.area;
        this.sourceLocation = data.success.location;
        this.sourceCounties = data.success.county;
      },
      error => {
        console.log(error, 'this.areaLocationData error')
      });
  }

  search() {
    this.enableTab1 = false;
    this.enableTab2 = true;
    this.loading = true;

    let dateObj = this.userService.getSearchParameters();
    let requestObj = {
      targetCities: this.targetCities,
      targetLocation: this.targetLocation,
      targetCounties: this.targetCounties,
      from_date: dateObj.from_date,
      to_date: dateObj.to_date
    }

  
   // Getting data for Road Analysis Data Table
    this.speedLocationService.getRoadParkingData(requestObj).subscribe(
      (data: any) => {
        this.loading = false;
        this.roadParkingEvent = data.success;
      },
      error => {
        this.loading = false;
        console.log(error, 'this.roadParkingEvent error')
      });
   // Getiing Data for Maps
    this.speedLocationService.getRoadParkingDataMap(requestObj).subscribe(
      (data: any) => {
        this.loading = false;
        this.roadSearched = data.success;
        this.showMap = true;
        this.drawRoadMap();
      },
      error => {
        this.loading = false;
        console.log(error, 'this.roadSearched error')
      });

  }

  onMoveToTarget() {
    console.log('onMoveToTarget')
    this.speedLocationService.getAreaByCountyId({ targetCounties: this.targetCounties }).subscribe(
      (data: any) => {
        this.sourceCities = data.success.area;
        this.sourceLocation = data.success.location;
      },
      error => {
        console.log(error, 'this.areaLocationData error')
      });
  }
 
  }