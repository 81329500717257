import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';  
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { ChartModule } from 'primeng/chart';
import { MessagesComponent } from './messages/messages.component';
import { ActivityFeedComponent } from './activity-feed/activity-feed.component';
import { SpeedLocationComponent } from '../speedlocation/speedlocation.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { SideNavComponent } from '../shared/sideNavigation/sidenav.component';
import { AuthGuard } from '../_guards/auth.guard';
import { PickListModule} from 'primeng/picklist';
import {TabViewModule} from 'primeng/tabview';
import {DataViewModule} from 'primeng/dataview';
import {PaginatorModule} from 'primeng/paginator';
import { SpeedLocationService } from '../_services/speedlocation.service';
import { ParkingLocationService } from '../_services/parkinglocation.service';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {LeafletMarkerClusterModule} from '@asymmetrik/ngx-leaflet-markercluster'

//HIghCharts DrillDown Modules
import * as  Highcharts from 'highcharts';
import  More from 'highcharts/highcharts-more';
More(Highcharts);
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);

import { StreetParkingLeafComponent} from '../streetparkingleaf/streetparkingleaf.component'

import {GMapModule} from 'primeng/gmap';
import { SpeedOnRoadComponent } from '../speedonroad/speedonroad.component';

import {StreetParkingComponent} from '../streetparking/streetparking.component'


import { RoadCongestionComponent } from '../roadcongestion/roadcongestion.component';
import { ParkingLocationLeafComponent } from '../parkinglocationleaf/parkinglocationleaf.component';

import { IndoorDataService } from "../_services/indoordata.service";
import { UtilityService } from "../_services/utility.service"; 
import {TableModule} from 'primeng/table';
//import { MyClusterComponent } from '../mycluster/mycluster.component';
import { RoadparkingComponent } from '../roadparking/roadparking.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {ListboxModule} from 'primeng/listbox';
import {DropdownModule} from 'primeng/dropdown';


const homeRoute: Routes = [
    { path: '', component: HomeComponent,  canActivate: [AuthGuard]  },
    { path: 'home', component: HomeComponent,  canActivate: [AuthGuard]  },
    { path: 'speed-location', component: SpeedLocationComponent,  canActivate: [AuthGuard]  },
    { path: 'speed-on-road', component: SpeedOnRoadComponent,  canActivate: [AuthGuard]  },
    { path: 'street-parking', component: StreetParkingComponent,  canActivate: [AuthGuard]  },
     { path: 'road-congestion', component: RoadCongestionComponent,  canActivate: [AuthGuard]  },
    { path: 'parking', component: StreetParkingLeafComponent,  canActivate: [AuthGuard]  },
   { path: 'parking-location', component: ParkingLocationLeafComponent,  canActivate: [AuthGuard]  },
    { path: 'roadparking', component: RoadparkingComponent,  canActivate: [AuthGuard]  },

];

@NgModule({
  imports: [
    ChartModule, HighchartsChartModule,FormsModule, CommonModule,PaginatorModule,MultiSelectModule,
    ListboxModule,DropdownModule,
    PickListModule,TabViewModule,DataViewModule,TableModule,GMapModule,
    LeafletModule.forRoot(),
    LeafletMarkerClusterModule,
    RouterModule.forRoot(homeRoute, { useHash: true })
  ],
  exports: [RouterModule,LeafletModule,LeafletMarkerClusterModule],
  declarations: [
    SpeedLocationComponent,SpeedOnRoadComponent,
    StreetParkingComponent,RoadCongestionComponent,RoadparkingComponent,ParkingLocationLeafComponent,
    HomeComponent,
    MessagesComponent,
    SideNavComponent,
    ActivityFeedComponent,
    StreetParkingLeafComponent
  ],
  providers: [SpeedLocationService, IndoorDataService, UtilityService, ParkingLocationService,
   
  ]
})

export class HomeRoutingModule { }