import { Component, OnInit } from '@angular/core';
import { SpeedLocationService } from './../_services/speedlocation.service';
import { IndoorDataService } from "./../_services/indoordata.service";
import { UserService } from "./../_services/user.service";
import { UtilityService } from "./../_services/utility.service"; 
import { API_CONSTANTS} from  './../constants/api.constants'
import {SelectItem} from 'primeng/api';
import * as _ from 'lodash';

import { icon, polygon, circle, latLng, Map, marker,geoJSON, Marker, LatLngExpression, point, polyline, tileLayer } from 'leaflet';
import 'leaflet.awesome-markers';

import * as Highcharts from 'highcharts';

declare var L;
declare var HeatmapOverlay;

@Component({
  selector: 'app-parking-location',
  templateUrl: './roadcongestion.component.html',
  styleUrls: ['./roadcongestion.component.css']
})
export class RoadCongestionComponent implements OnInit {

  browserChartOptions: any;
  speedLocationTable: any;
  thirdTabMapData: any;
  roadsSearched = [];
  showMap: boolean = false;
  sourceCities = []
  sourceLocation = []
  targetLocation = [];
  targetCities = [];

  sourceCounties = [];
  targetCounties = []

  citiesSearched = [];
  targetLocationStr = '';


  enableTab1: boolean = true;
  enableTab2: boolean = false;
  enableTab3: boolean = false;
  loading: boolean = false;

  location3rdTab: any = [];
  layers = [];

  leafmap: any;
  data = {
    data: []
  };
  bounds = [];
  mapData = []

  leafmap1: any;
  data1 = {
    data: []
  };
  bounds1 = [];
  mapData1 = []
  index: number = 0;

  markerClusterData: any[] = [];
  markerClusterData1: any[] =[];
  cols: any[];

  //  myIcon = L.icon(API_CONSTANTS.LEAFLET.ICON.MARKER);

  myIcon = L.AwesomeMarkers.icon({ 
    icon: 'car', 
    markerColor: 'green',
    prefix: 'fa', 
    iconColor: 'darkred',
  });

  myIcon1 = L.AwesomeMarkers.icon({ 
    icon: 'car', 
    markerColor: 'green',
    prefix: 'fa', 
    iconColor: 'darkred',
  //  className: 'awesome-marker awesome-marker-square'
  });

  customOptions =   {'maxWidth': '1200', 'width': '800', 'className': 'custom'};

  heatmapLayer = new HeatmapOverlay(API_CONSTANTS.LEAFLET.HEATMAP.HEATMAP_LAYER);

  options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      API_CONSTANTS.LEAFLET.HEATMAP.OPTIONS_LAYERS),
      this.heatmapLayer,
    ],
    zoom: 13,
  };

  layersControl = {
    baseLayers: {
      'Open Street Map': tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL
      ),
      'Open Cycle Map': tileLayer('http://{s}.tile.opencyclemap.org/{z}/{x}/{y}.png',
      API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL
      )
    },
  }

  // heatmapLayer1 = new HeatmapOverlay(API_CONSTANTS.LEAFLET.HEATMAP.HEATMAP_LAYER );
  layersControl1 = {
    baseLayers: {
      'Open Cycle Map': tileLayer('http://{s}.tile.opencyclemap.org/{z}/{x}/{y}.png',
       API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL),
      'Open Street Map': tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', 
       API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL)
    },
    position: 'bottomright' 
  }

	// Values to bind to Leaflet Directive
	layersControlOptions1 = { position: 'bottomright' };
    options1 = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      API_CONSTANTS.LEAFLET.HEATMAP.OPTIONS_LAYERS),
    ],
    maxZoom: API_CONSTANTS.LEAFLET.HEATMAP.LAYERS_CONTROL.maxZoom ,
    zoom:4,
  };

  days: SelectItem[] = [
      {label:'Sunday', value:{id:1, name: 'Sunday', code: '1'}},
      {label:'Monday', value:{id:2, name: 'Monday', code: '2'}},
      {label:'Tuesday', value:{id:3, name: 'Tuesday', code: '3'}},
      {label:'Wednesday', value:{id:4, name: 'Wednesday', code: '4'}},
      {label:'Thursday', value:{id:5, name: 'Thursday', code: '5'}},
      {label:'Friday', value:{id:6, name: 'Friday', code: '6'}},
      {label:'Saturday', value:{id:7, name: 'Saturday', code: '7'}}
  ];

  selectedDays:SelectItem[];
  selectedConfidenceLevel=25
  selectedCongestionIndicatior = 25;
  selectedMinimumRoadLength = 500;

  confidenceLevel: SelectItem[] = [
    {label:'25', value:{id:1, name: 25, code: 25}}
  ];
 

  Highcharts = Highcharts; // required
  chartConstructor = 'chart'; // optional string, defaults to 'chart'


barChartOptions = {
  chart: {
      type: 'column'
  },
  title: {
      text: 'World\'s largest cities per 2017'
  },
  subtitle: {
      text: 'Source: <a href="http://en.wikipedia.org/wiki/List_of_cities_proper_by_population">Wikipedia</a>'
  },
  xAxis: {
      type: 'category',
      labels: {
          rotation: -45,
          style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
          }
      }
  },
  yAxis: {
      min: 0,
      title: {
          text: 'Population (millions)'
      }
  },
  legend: {
      enabled: false
  },
  tooltip: {
      pointFormat: 'Population in 2017: <b>{point.y:.1f} millions</b>'
  },
  series: [{
      name: 'Population',
      data: [
          ['Shanghai', 24.2],
          ['Beijing', 20.8],
          ['Karachi', 14.9],
          ['Shenzhen', 13.7],
          ['Guangzhou', 13.1],
          ['Istanbul', 12.7],
          ['Mumbai', 12.4],
          ['Moscow', 12.2],
          ['São Paulo', 12.0],
          ['Delhi', 11.7],
          ['Kinshasa', 11.5],
          ['Tianjin', 11.2],
          ['Lahore', 11.1],
          ['Jakarta', 10.6],
          ['Dongguan', 10.6],
          ['Lagos', 10.6],
          ['Bengaluru', 10.3],
          ['Seoul', 9.8],
          ['Foshan', 9.3],
          ['Tokyo', 9.3]
      ],
      dataLabels: {
          enabled: true,
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y:.1f}', // one decimal
          y: 10, // 10 pixels down from the top
          style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
          }
      }
  }]
};




// chartCallback = function (chart) { ... } // optional function, defaults to null
updateFlag = false; // optional boolean
oneToOneFlag = true; // optional boolean, defaults to false
runOutsideAngular = false; // optional boolean, defaults to false


  constructor(private speedLocationService: SpeedLocationService,
    private _indoorDataService: IndoorDataService, private userService: UserService,
    private utilityService : UtilityService
  ) { }

  ngOnInit() {
   // this.selectedConfidenceLevel = this.confidenceLevel[0];
    this.countyData()


    this.cols = [
      { field: 'hour_minute', header: ' Time Period ' },
      { field: 'road_osmid', header: ' OSM ID' },
      { field: 'name', header: 'Road Name ' },
      { field: 'ref', header: 'Ref' },
      { field: 'roadtype', header: 'Road Type' },
      { field: 'directionname', header: 'Direction Name' },
      { field: 'congestion_indicator', header: 'Congestion Indicator' },
      { field: 'averagespeed', header: 'Average Speed' },
      { field: 'maxspeed', header: 'Speed Limit' },
      { field: 'average_traversaltime_seconds', header: 'Average Traversal Time' },
      { field: 'sum_total_trackingpoints', header: 'Confidence Level' }
  ];
  }

  
  countyData() {
    this.speedLocationService.getCounty().subscribe(
      (data: any) => {
        this.sourceCounties = data.success.county;
      },
      error => {
        console.log(error, 'this.countyData error')
      });
  }

  areaLocationData() {
    this.speedLocationService.getAreaLocation().subscribe(
      (data: any) => {
        this.sourceCities = data.success.area;
        this.sourceLocation = data.success.location;
        this.sourceCounties = data.success.county;
      },
      error => {
        console.log(error, 'this.areaLocationData error')
      });
  }

  search() {
    this.enableTab1 = false;
    this.enableTab2 = true;
    this.enableTab3 = false;
    this.loading = true;

    let dateObj = this.userService.getSearchParameters();
    let requestObj = {
      targetCities: this.targetCities,
      targetLocation: this.targetLocation,
      targetCounties: this.targetCounties,
      from_date: dateObj.from_date,
      to_date: dateObj.to_date,
      selectedDays : this.selectedDays,
      selectedConfidenceLevel : this.selectedConfidenceLevel,
      selectedMinimumRoadLength : this.selectedMinimumRoadLength,
      congestionIndicatior : this.selectedCongestionIndicatior
    }

    this.targetLocationStr = '';
    this.targetLocation.forEach((element, i) => {
      if (i > 0) {
        this.targetLocationStr += ', '
      }
      this.targetLocationStr += element.fclass.toUpperCase();
    });

    this.speedLocationService.searchRoadCongestion(requestObj).subscribe(
      (data: any) => {
        this.loading = false;

        this.citiesSearched = data.success;

        this.drawCityMap()
        this.showMap = true;
      },
      error => {
        this.loading = false;
        console.log(error, 'this.citiesSearched error')
      });

      this.enableTab1 = false;
      this.enableTab2 = true;
      this.enableTab3 = false;
      
      this.loading = true;
      this.showMap = true;
      this.index = 1;
      window.dispatchEvent(new Event('resize'));
  }

  handleChange(e) {
    window.dispatchEvent(new Event('resize'));
    this.index = 1;
}


  drawCityMap() {

    const markerData: any[] = [];

    this.citiesSearched.forEach((element, i) => {
      let lat = parseFloat (element.centre_lat);
      let lng = parseFloat (element.centre_lon);
      this.data.data.push({
          lat: lat,
          lng: lng,
          count: 1
        });

        this.citiesSearched[i]['average_traversaltime_seconds'] = parseFloat(element['average_traversaltime_seconds']) ;
        this.citiesSearched[i]['sum_total_trackingpoints'] = parseFloat(element['sum_total_trackingpoints']);  
        this.citiesSearched[i]['averagespeed'] = parseFloat(element['averagespeed']) ;
        this.citiesSearched[i]['congestion_indicator'] = parseFloat(element['congestion_indicator']) ;
        this.citiesSearched[i]['maxspeed'] = parseFloat(element['maxspeed']) ;
        this.citiesSearched[i]['road_osmid'] = parseFloat(element['road_osmid']) ;
      //  this.citiesSearched[i]['hour_minute'] = parseFloat(element['hour_minute']) ;
        

        

      let contentString = this.utilityService.drawCongestionInfoWindow(element, 'CONGESTION');

      this.myIcon = L.AwesomeMarkers.icon({ 
        icon: 'car', //API_CONSTANTS.FONT_AWESOME.ICONS[element.fclass], 
        markerColor: 'green',
        prefix: 'fa', 
        iconColor: 'darkred',
      });

      this.bounds.push([lat, lng]);
      markerData.push(marker([lat, lng], {icon: this.myIcon}).bindPopup(contentString).openPopup());

    });

    if (markerData.length > 0) {
      this.heatmapLayer.setData(this.data);
      this.markerClusterData = markerData;

       //this.leafmap.addLayer(markers);
      this.leafmap.fitBounds(this.bounds);
      this.leafmap.setZoom(10);

    } 
  }




  onMapReady(map: L.Map) {
    this.leafmap = map
  }


  onMapReadyDetails(map: L.Map) {
    this.leafmap1 = map
  }



  onMoveToTarget() {
    console.log('onMoveToTarget')
    this.speedLocationService.getRoadCongestionAreaByCountyId({ targetCounties: this.targetCounties }).subscribe(
      (data: any) => {
        this.sourceCities = data.success.area;
        this.sourceLocation = data.success.location;
      },
      error => {
        console.log(error, 'this.areaLocationData error')
      });
  }

  clicked(event: Event) {
    console.log(event, 'event')
  }

  selectCity(event: Event, city: any) {
    this.location3rdTab = [];
    this.location3rdTab.push(city)

    this.enableTab1 = false;
    this.enableTab2 = false;
    this.enableTab3 = true;

    console.log(city, 'city')

    let dateObj = this.userService.getSearchParameters();
    let requestObj = {
      targetCities: this.targetCities,
      targetLocation: this.targetLocation,
      targetCounties: this.targetCounties,
      from_date: dateObj.from_date,
      to_date: dateObj.to_date,
      selected_osm_id: city.road_osmid,
      selectedDays : this.selectedDays,
      selectedConfidenceLevel : this.selectedConfidenceLevel,
    }

    this.speedLocationService.getRoadCongestionTab3(requestObj).subscribe(
      (data: any) => {

        this.roadsSearched = data.success;
        this.thirdTabMapData = data.thirdTabTable;

        this.locationAnalysis()
      }, error => {
        console.log(error, 'this.areaLocationData error')
      });


    this.enableTab1 = false;
    this.enableTab2 = false;
    this.enableTab3 = true;
    this.index = 2;
    window.dispatchEvent(new Event('resize'));
  }

  locationAnalysis() {
    const markerData: any[] = [];
    this.browserChartOptions = {};
    var  mylines:any;
    var myStyle = {
        "color": "#ff7800",
        "weight": 5,
        "opacity": 0.65
    };

   
       
     this.thirdTabMapData.forEach((element, i) => {

      let mylines = JSON.parse(element.geojson_geom);
        L.geoJSON(mylines, {style: myStyle}).addTo(this.leafmap1);

      let lat = parseFloat (element.centre_lat);
      let lng = parseFloat (element.centre_lon);

     
      this.data1.data.push({
        lat: lat,
        lng: lng,
        count: 1
      });

      let contentString = this.utilityService.drawCongestionInfoWindowTab3(element, 'CONGESTION_TAB3')
      this.bounds1.push([lat, lng]);
       markerData.push(marker([lat, lng], {icon: this.myIcon1}).bindPopup(contentString).openPopup());   
    });
  
    if (markerData.length > 0) {
      this.markerClusterData1 = markerData;
      this.leafmap1.fitBounds(this.bounds);
      this.leafmap1.setZoom(13);
    }
    this.setBarChart();

  }

  setBarChart(){
    let series = []
    series.push({  "name": "Road",  "colorByPoint": true,   "data": []});

    this.thirdTabMapData.forEach((element, i) => {

      series[0]['data'].push({ "name": element.name,
               "y": parseFloat(element.maxspeed),
               "drilldown": element.ref,
          })
     });

     let drillSeries = [];
      drillSeries.push({
        "name": this.thirdTabMapData[0].name,
        "id": this.thirdTabMapData[0].ref,
        "data": []});
        
     this.roadsSearched.forEach((element, i) => {
        drillSeries[0]['data'].push([
          element.hour_minute,
          parseFloat( element.averagespeed)]);

         // this.roadsSearched[i]['hour_minute'] = parseFloat(element['hour_minute']) ;
          this.roadsSearched[i]['congestion_indicator'] = parseFloat(element['congestion_indicator']);  
          this.roadsSearched[i]['averagespeed'] = parseFloat(element['averagespeed']) ;
          this.roadsSearched[i]['average_traversaltime_seconds'] = parseFloat(element['average_traversaltime_seconds']) ;
     
     });



    let uniqueDirection = _.uniqBy(this.roadsSearched, 'directionname'); 
    uniqueDirection.forEach((direction: any, i) => {
      series[0]['data'].push({
        "name": direction.directionname,
        "y": parseFloat(direction.averagespeed),
        "drilldown": direction.directionname,
      })

      drillSeries.push({
        "name": direction.directionname,
        "id": direction.directionname,
        "data": []
      });

      this.roadsSearched.forEach((element, j) => {
        if (direction.directionname == element.directionname) {
          drillSeries[i + 1]['data'].push([element.hour_minute,
          parseFloat(element.averagespeed)]);
        }

      });
    });

    this.browserChartOptions = {
      chart: {
          type: 'column'
      },
      title: {
          text: 'Average Speed By Direction'
      },
      subtitle: {
          text: 'Click the columns to view average speed at different time'
      },
      xAxis: {
          type: 'category'
      },
      yAxis: {
          title: {
              text: 'Average Speed'
          }
    
      },
      legend: {
          enabled: false
      },
      plotOptions: {
          series: {
              borderWidth: 0,
              dataLabels: {
                  enabled: true,
                  format: '{point.y:.1f} KMPH'
              }
          }
      },
    
      tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}, {point.ref}, {point.road_osmid}</span> <br/>: Average Speed <b>{point.y:.2f}</b> KMPH <br/>'
      },
    
      series : series,
      "drilldown": {
          "series": drillSeries
      }
    };

  }

}



