export const API_CONSTANTS = {

    SERVER : 'https://guicontractor2-trunk-api.anadue.com/api',   
  // SERVER : 'http://localhost:8000/api', 

    USER : {
        LOGIN : 'login',
        REGISTER : 'register',
        DETAILS : 'details',
        LOGOUT : 'logout'
    },
    SPEED_LOCATION : {
        SEARCH_AREA_LOCATION : 'speedlocationarea',
        GET_AREA_LOCATION : 'getarealocation',

        GET_COUNTY : 'getCounty',
        GET_AREA_BY_COUNTY : 'getAreaByCountyId',
        SEARCH_AREA_ROAD : 'speedroadarea',
        SEARCH_AREA_ROAD_MAP : 'speedroadmap',
        SEARCH_SPECIFIC_LOCATION : 'speedLocationOfSpecifcArea',
        GET_ROAD_PARKING_DATA : 'getRoadParkingData',
        GET_ROAD_PARKING_DATA_MAP : 'getRoadParkingDataForMap',
        GET_PARKING_COUNTY : 'parking/getCounty',
        // GET_AREA_BY_COUNTY : 'getAreaByCountyId',
        // SEARCH_AREA_ROAD : 'speedroadarea',
        // SEARCH_AREA_ROAD_MAP : 'speedroadmap',
        // SEARCH_SPECIFIC_LOCATION : 'speedLocationOfSpecifcArea',
    },
    PARKING_LOCATION : {
        SEARCH_AREA_LOCATION : 'parking/parkingLocationArea',
        GET_AREA_LOCATION : 'parking/getAreaLocation',
        GET_COUNTY : 'parking/getCounty',
        GET_AREA_BY_COUNTY : 'parking/getAreaByCountyId',
        SEARCH_SPECIFIC_LOCATION : 'parking/parkingLocationOfSpecifcArea',
    },
    ROAD_CONGESTION : {
        SEARCH_ROAD_CONGESTION : 'roadCongestion/roadCongestionArea',
        GET_AREA_LOCATION : 'parking/getAreaLocation',
        GET_ROAD_CONGESTION_BY_COUNTY : 'roadCongestion/getRoadCongestionAreaByCountyId',
        GET_ROAD_CONGESTION_TAB3 : 'roadCongestionOfSpecifcArea',
    },

    LEAFLET : {
        HEATMAP : {
            HEATMAP_LAYER  : {
                radius: 0.0009,
                maxOpacity: .4,
                scaleRadius: true,
                useLocalExtrema: true,
                latField: 'lat',
                lngField: 'lng',
                valueField: 'count'
              }, 
            OPTIONS_LAYERS : {
                maxZoom: 21,
                minZoom: 2,
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                detectRetina: true
            },
            LAYERS_CONTROL : { maxZoom: 21, attribution: '...' }
        },
        ICON : {
            MARKER : {
                iconUrl: 'leaflet/marker-icon.png',
                iconSize: [25, 41],
                iconAnchor: [13, 41],
                popupAnchor: [-3, -76],
                shadowUrl: 'leaflet/marker-icon.png'
              }
        }
    },

    FONT_AWESOME :{
        ICONS : {
            parking : 'parking',
            park : "tree", dog_park : 'tree',
            archaeological : 'store',
            arts_centre : "artstation",
            artwork : "artstation",
            atm : "money-check-alt",
            bank : "money-check-alt",
            bar : "glass-cheers", pub : "glass-cheers",
            school : "school", pitch : "magic",
            car : "car", car_rental: 'car', car_wash: 'car', 
            motorcycle : "motorcycle", cafe : 'coffee',
            beauty_shop : "shopping-cart", bookshop : "shopping-cart", 
            bicycle_rental : "bicycle",
            bicycle_shop : "bicycle",
            castle : 'fort-awesome', tower : "monument",
            doctors : 'user-md', hospital: "hospital-symbol",
            fast_food : 'utensils', food_court : 'utensils',
            hostel : "hotel", hotel : "hotel", 
            sports_centre : "football-ball"

        }
    }

}
