import { Component, OnInit } from '@angular/core';
import { SpeedLocationService } from '../_services/speedlocation.service';
import { IndoorDataService } from "../_services/indoordata.service";
import { UserService } from "../_services/user.service";
import { UtilityService } from "../_services/utility.service"; 

declare const google: any;
//import {} from '@types/googlemaps';
@Component({
  selector: 'app-speed-location',
  templateUrl: './streetparking.component.html',
  styleUrls: ['./streetparking.component.css']
})
export class StreetParkingComponent implements OnInit {

  heatmap: any;
  map: any;
  mapallpoints:any;
  triangleCoords: { lat: number; lng: number; }[];
  showMap: boolean = false;
  sourceCities = [];
  sourceLocation = [];
  targetLocation = [];
  targetCities = [];

  sourceCounties = [];
  targetCounties = [];

  roadParkingEvent = [];
  roadSearched = [];

  title: string = 'Mapping with Angular';
  lat: number = 53.40642945;
  lng: number = -6.287101473674747;
  zoom: number = 13;


  enableTab1: boolean = true;
  enableTab2: boolean = false;
  loading: boolean = false;

  location3rdTab:any = [];


  options: any;
  overlays: any[];
  markerTitle: string;
  selectedPosition: any;
  infoWindow: any;
  draggable: boolean;
  dialogVisible: boolean;

  constructor(private speedLocationService: SpeedLocationService,
    private _indoorDataService: IndoorDataService, private userService: UserService,
    private utilityService : UtilityService
  ) { }

  ngOnInit() {
    this.countyData()
  }

  handleMapClick(event) {
    this.dialogVisible = true;
    this.selectedPosition = event.latLng;
  
}
//   initOverlays() {
//     this.options = {
//       center: {lat: 36.890257, lng: 30.707417},
//       zoom: 13
//   };
//   this.infoWindow = new google.maps.InfoWindow();
//         this.overlays = [
//             new google.maps.Marker({position: {lat: 36.879466, lng: 30.667648}, title:"Konyaalti"}),
//             new google.maps.Marker({position: {lat: 36.883707, lng: 30.689216}, title:"Ataturk Park"}),
//             new google.maps.Marker({position: {lat: 36.885233, lng: 30.702323}, title:"Oldtown"}),
//             new google.maps.Polygon({paths: [
//                 {lat: 36.9177, lng: 30.7854},{lat: 36.8851, lng: 30.7802},{lat: 36.8829, lng: 30.8111},{lat: 36.9177, lng: 30.8159}
//             ], strokeOpacity: 0.5, strokeWeight: 1,fillColor: '#1976D2', fillOpacity: 0.35
//             }),
//             new google.maps.Circle({center: {lat: 36.90707, lng: 30.56533}, fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: 1500}),
//             new google.maps.Polyline({path: [{lat: 36.86149, lng: 30.63743},{lat: 36.86341, lng: 30.72463}], geodesic: true, strokeColor: '#FF0000', strokeOpacity: 0.5, strokeWeight: 2})
//         ];
// }


  countyData() {
    this.speedLocationService.getCounty().subscribe(
      (data: any) => {
        this.sourceCounties = data.success.county;
      },
      error => {
        console.log(error, 'this.countyData error')
      });
  }

  areaLocationData() {
    this.speedLocationService.getAreaLocation().subscribe(
      (data: any) => {
        this.sourceCities = data.success.area;
        this.sourceLocation = data.success.location;
        this.sourceCounties = data.success.county;
      },
      error => {
        console.log(error, 'this.areaLocationData error')
      });
  }

  search() {
    this.enableTab1 = false;
    this.enableTab2 = true;
    this.loading = true;

    let dateObj = this.userService.getSearchParameters();
    let requestObj = {
      targetCities: this.targetCities,
      targetLocation: this.targetLocation,
      targetCounties: this.targetCounties,
      from_date: dateObj.from_date,
      to_date: dateObj.to_date
    }

  
   // Getting data for Road Analysis Data Table
    this.speedLocationService.getRoadParkingData(requestObj).subscribe(
      (data: any) => {
        this.loading = false;
        this.roadParkingEvent = data.success;
      },
      error => {
        this.loading = false;
        console.log(error, 'this.roadParkingEvent error')
      });
   // Getiing Data for Maps
    this.speedLocationService.getRoadParkingDataMap(requestObj).subscribe(
      (data: any) => {
        this.loading = false;
        this.roadSearched = data.success;
        this.showMap = true;
        
        this.initGoogleMap();
        this.roadSearched.forEach((element, i) => {
          if (i == this.roadSearched.length / 2) {
            this.lat = parseFloat(element.event_lon);
            this.lng = parseFloat(element.event_lat);
          }
        });
        
      },
      error => {
        this.loading = false;
        console.log(error, 'this.roadSearched error')
      });

  }

  onMoveToTarget() {
    console.log('onMoveToTarget')
    this.speedLocationService.getAreaByCountyId({ targetCounties: this.targetCounties }).subscribe(
      (data: any) => {
        this.sourceCities = data.success.area;
        this.sourceLocation = data.success.location;
      },
      error => {
        console.log(error, 'this.areaLocationData error')
      });
  }

  clicked(event: Event) {
    console.log(event, 'event')
  }

  initGoogleMap(){
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: this.zoom, center: { lat: this.lat, lng: this.lng }
    });
   
    this.heatmap = new google.maps.visualization.HeatmapLayer({
       data: this.getPoints(), map: this.map
    });

  this.getMarkerPoints() 
  }


  getPoints() {
    let dataPointsList = [];
    this.roadSearched.forEach((element, i) => {
      dataPointsList.push({ 
        location: new google.maps.LatLng(parseFloat(element.event_lat),
        parseFloat(element.event_lon)), 
        weight: parseFloat(element.speedevent_roadspeedlimit )* 100
      })
    });
    return dataPointsList;
  }

  getMarkerPoints() {
       this.roadSearched.forEach((element, i) => {
       
      let contentString = this.utilityService.getGoogleMapInfoWindow(element,"parking");
      var marker =  new google.maps.Marker({
        position: new google.maps.LatLng(parseFloat(element.event_lat),
        parseFloat(element.event_lon)),
        title:  element.roadname + ' - '+element.roadref,
        popwin:  contentString,
        map: this.map
      });

      var infowindow = new google.maps.InfoWindow({
        content: contentString
      });

      
      google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(this.map, marker);
      });

      // Location Zooming 
      var  bounds  = new google.maps.LatLngBounds();
      var  loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
      bounds.extend(loc);
      //center the map to the geometric center of all markers
      this.map.setCenter(bounds.getCenter());
      this.map.panToBounds(bounds);
       // Location Zooming 
       
      });
  }


  

}