import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import * as _ from 'lodash';
 
@Injectable()
export class UtilityService {
 
  constructor(private _http: Http) {}
  

  getGoogleMapInfoWindow(element, actionEvent){

  
    let contentString
    if (actionEvent == 'parking') {
      let datefevent = new Date(element.endparktime*1000).toLocaleString();
      let poisName = '';
      if(element.pois_name) {
        poisName =  element.pois_name;
      }

      contentString = '<div id="content"><Table  class="infoWindowMap"  border = "1" cellpadding="4" cellspacing ="3" >'
      +'<tr><td colspan="2" ><h6 id="firstHeading" class="firstHeading">PARKING EVENT : '+datefevent +'<h6></td></tr>'
      +'<tr><td>VECHILE ID</td><td>'+element.vehicle +'</td></tr>'
      +'<tr><td>PARKING DURATION</td><td>'+element.duration +'</td></tr>'
      +'<tr><td>PARKING LOCATION</td><td>'+element.event_lon+' , '+element.event_lat+'</td></tr>'
      +'<tr><td>ROAD</td><td>'+element.roadname +' '+element.roadref+' '+poisName+'</td></tr>'
      +'<tr><td>ROAD ID</td><td>'+element.road_osmid +'</td></tr>'
      +'</table></div>';

    } else {
      let datefevent = new Date(element.acquisition_time*1000).toLocaleString();
    contentString = '<div id="content"><Table  class="infoWindowMap"  border = "1" cellpadding="4" cellspacing ="3" >'
    +'<tr><td colspan="2" ><h6 id="firstHeading" class="firstHeading">SPEEDING EVENT : '+datefevent +'<h6></td></tr>'
    +'<tr><td>VECHILE SPEED</td><td>'+element.speedevent_vehiclespeed +'</td></tr>'
    +'<tr><td>ROAD SPEEDLIMIT</td><td>'+element.speedevent_roadspeedlimit +'</td></tr>'
    +'<tr><td>ROAD</td><td>'+element.roadname +' '+element.roadref+'</td></tr>'
    +'<tr><td>VECHILE ID</td><td>'+element.vehicle +'</td></tr>'
    +'<tr><td>ROAD ID</td><td>'+element.road_osmid +'</td></tr>'
    +'</table></div>';
    }

    return contentString;
  }

  drawLeafletInfoWindow(element, actionEvent){
    let contentString = '';
    if (actionEvent == 'parking-location') {

       contentString = '<Table  class="infoWindowMap"  width="100%" border = "1" cellpadding="4" cellspacing ="3" >'
      + '<tr><td colspan="2" ><h6 id="firstHeading" class="firstHeading">PARKING LOCATION : <h6></td></tr>'
      + '<tr><td>PARKING LOCATION EVENTS</td><td>' + element.totalevents + '</td></tr>'
      + '<tr><td>PARKING LOCATION</td><td>' + element.centrelongitude + ' , ' + element.centrelatitude + '</td></tr>'
      + '<tr><td>LOCATION</td><td>' + element.poisname + '</td></tr>'
      + '<tr><td>LOCATION ID</td><td>' + element.pois_osmid + '</td></tr>'
      + '</table>';

    }  else if(actionEvent == 'parking') {
      let datefevent = new Date(element.endparktime * 1000).toLocaleString();
      let poisName = '';
      if (element.pois_name) {
        poisName = element.pois_name;
      }

       contentString = '<Table  class="infoWindowMap"  width="100%" border = "1" cellpadding="4" cellspacing ="3" >'
        + '<tr><td colspan="2" ><h6 id="firstHeading" class="firstHeading">PARKING EVENT : ' + datefevent + '<h6></td></tr>'
        + '<tr><td>VECHILE ID</td><td>' + element.vehicle + '</td></tr>'
        + '<tr><td>PARKING DURATION</td><td>' + element.duration + '</td></tr>'
        + '<tr><td>PARKING LOCATION</td><td>' + element.event_lon + ' , ' + element.event_lat + '</td></tr>'
        + '<tr><td>ROAD</td><td>' + element.roadname + ' ' + element.roadref + ' ' + poisName + '</td></tr>'
        + '<tr><td>ROAD ID</td><td>' + element.road_osmid + '</td></tr>'
        + '</table>';
    } else if(actionEvent == 'roadspeed') {

      let datefevent = new Date(element.acquisition_time*1000).toLocaleString();
    contentString = '<div id="content"><Table   class="infoWindowMap"  border = "1" cellpadding="4" cellspacing ="3" >'
    +'<tr><td colspan="2" ><h6 id="firstHeading" class="firstHeading">SPEEDING EVENT : '+datefevent +'<h6></td></tr>'
    +'<tr><td>VECHILE SPEED</td><td>'+element.speedevent_vehiclespeed +'</td></tr>'
    +'<tr><td>ROAD SPEEDLIMIT</td><td>'+element.speedevent_roadspeedlimit +'</td></tr>'
    +'<tr><td>ROAD</td><td>'+element.roadname +' '+element.roadref+'</td></tr>'
    +'<tr><td>VECHILE ID</td><td>'+element.vehicle +'</td></tr>'
    +'<tr><td>ROAD ID</td><td>'+element.road_osmid +'</td></tr>'
    +'</table></div>';
    }  else if(actionEvent == 'location-details') {

      let datefevent = new Date(element.time*1000).toLocaleString();
    contentString = '<div  class="infoWindowMapDetails" id="content"><Table    border = "1" cellpadding="4" cellspacing ="3" >'
    +'<tr><td colspan="2" ><h6 id="firstHeading" class="firstHeading">SPEEDING EVENT : '+datefevent +'<h6></td></tr>'
    +'<tr><td>VECHILE SPEED</td><td>'+element.event_vehiclespeed +'</td></tr>'
    +'<tr><td>ROAD SPEEDLIMIT</td><td>'+element.event_roadspeedlimit +'</td></tr>'
    +'<tr><td>ROAD</td><td>'+element.roadname +' '+element.roadref+'</td></tr>'
    +'<tr><td>VECHILE ID</td><td>'+element.vehicle +'</td></tr>'
    +'<tr><td>ROAD ID</td><td>'+element.road_osmid +'</td></tr>'
    +'<tr><td>SPEEDING LOCATION</td><td>' + element.pois_lat + ' , ' + element.pois_lon + '</td></tr>'
    +'</table></div>';
    }
    return contentString;
  }

  drawLocationInfoWindow(element, type:string){
    type = type.toUpperCase();
    let  contentString = '<Table class="infoWindowMap" width="100%" border = "1" cellpadding="4" cellspacing ="3" >'
     + '<tr><td colspan="2" ><h6 id="firstHeading" class="firstHeading">'+type+' LOCATION EVENTS: <h6></td></tr>'
     + '<tr><td>'+type+' EVENTS</td><td>' + element.totalevents + '</td></tr>'
     + '<tr><td>'+type+' LOCATION</td><td>' + element.centrelongitude + ' , ' + element.centrelatitude + '</td></tr>'
     + '<tr><td>LOCATION NAME </td><td>' + element.poisname + '</td></tr>'
     + '<tr><td>LOCATION TYPE </td><td>' + element.fclass.toUpperCase() + '</td></tr>'
     + '<tr><td>LOCATION ID</td><td>' + element.pois_osmid + '</td></tr>'
     + '</table>';
     return contentString;
  }

  drawCongestionInfoWindow(element, type:string){
    type = type.toUpperCase();
    let  contentString = '<Table class="infoWindowMap" width="100%" border = "1" cellpadding="4" cellspacing ="3" >'
     + '<tr><td colspan="2" ><h6 id="firstHeading" class="firstHeading">'+type+' LOCATION EVENTS: <h6></td></tr>'
     + '<tr><td>'+type+' EVENTS</td><td>' + element.sum_total_trackingpoints + '</td></tr>'
     + '<tr><td>'+type+' LOCATION</td><td>' + element.centre_lat + ' , ' + element.centre_lon + '</td></tr>'
     + '<tr><td>LOCATION NAME </td><td>' + element.name + '</td></tr>'
     + '<tr><td>LOCATION TYPE </td><td>' + element.roadtype + '</td></tr>'
     + '<tr><td>ROAD ID</td><td>' + element.road_osmid + '</td></tr>'
     + '<tr><td>'+type+' INDICATOR</td><td>' + element.congestion_indicator + '</td></tr>'
     + '<tr><td>'+type+' DIRECTION</td><td>' + element.directionname + '</td></tr>'
     + '<tr><td>AVG TRAVERSAL TIME</td><td>' + element.average_traversaltime_seconds + '</td></tr>'
     + '<tr><td>TIME</td><td>' + element.hour_minute + '</td></tr>'
     + '</table>';
     return contentString;
  }

  drawCongestionInfoWindowTab3(element, type:string){
    type = type.toUpperCase();
    let  contentString = '<Table class="infoWindowMap" width="100%" border = "1" cellpadding="4" cellspacing ="3" >'
     + '<tr><td colspan="2" ><h6 id="firstHeading" class="firstHeading">ROAD CONGESTIONS <h6></td></tr>'
     + '<tr><td> LOCATION</td><td>' + element.centre_lat + ' , ' + element.centre_lon + '</td></tr>'
     + '<tr><td>ROAD NAME </td><td>' + element.name + '</td></tr>'
     + '<tr><td>ROAD TYPE </td><td>' + element.roadtype + '</td></tr>'
     + '<tr><td nowrap>ROAD LENGTH</td><td>' + element.length_meters + '</td></tr>'
     + '<tr><td>ROAD REF </td><td>' + element.ref + '</td></tr>'
     + '<tr><td>MAX SPEED </td><td>' + element.maxspeed + '</td></tr>'
     + '</table>';
     return contentString;
  }

  sortNumeric(dataObject, numericObj){
    if(dataObject.length > 0 && numericObj.length > 0) {
      dataObject.forEach((element, i) => {
        numericObj.forEach((elementKey, j) => {
          element[elementKey] = parseFloat(element[elementKey]);
        })
      });
    }
    return dataObject;
  }
  
}