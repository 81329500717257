import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.css']
})
export class ActivityFeedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
